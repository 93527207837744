export const HOME = "/";
export const INSTITUTIONAL = "/institucional";
export const FEATURES = "/features";
export const SOCII_PLUS = "/socii+";
export const ESPECIALISTS = "/especialistas";
export const PLANS = "/planos";
export const LP1 = "/landing-page-1";
export const LP2 = "/landing-page-2";
export const REGISTRO_MARCA_LP = "/rm1";
export const REGISTRO_MARCA_LP_V2 = "/rm2";
export const REGISTRO_MARCA_LP_V3 = "/rm0";
export const REGISTRO_MARCA_LP_V4 = "/rm1Analise";
export const REGISTRO_MARCA_LP_V5 = "/rm3";
export const REGISTRO_MARCA_LP_V5_ANALISE = "/rm3Analise";
export const REGISTRO_MARCA_LP_V5_ANALISE_MIN = "/rm3analise";
export const CONTABILIZEI_LP = "/contabilizei";
export const CONTABILIZEI_LP_2 = "/contabilizeipromo";
export const RM_CONTABILIZEI_LP = "/rmcontabilizei";
export const RM_CONTABILIZEI_LP_2 = "/rmcontabilizeipromo";
export const VENTIUR_LP = "/ventiur";
export const RM_VENTIUR_LP = "/rmventiur";

export const NEW_LEAD = "/novo-lead";
export const LEAD = "/gerenciar-leads";

export const CONTRATOS_INVESTIMENTO_LP = "/contratosdeinvestimento";
export const RIW = "/riw";
export const APP = "/app";
export const ANALYSIS = "/analise-saude-juridica";
export const ANALYSIS_CALLBACK = "/analise-saude-juridica/:uid";

export const SIGN_IN_APP = "https://app.sociilaw.com/entrar";
export const INTRO_APP = "https://app.sociilaw.com/introducao";
export const APP_TERMS = "https://app.sociilaw.com/termos-de-uso-introducao";
export const APP_PRIVACY =
  "https://app.sociilaw.com/politica-de-privacidade-introducao";

export const WHATSAPP =
  "https://api.whatsapp.com/send?phone=5511954496048&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20suporte%20jur%C3%ADdico%20empresarial.";
export const WHATSAPP_COMERCIAL =
  "https://api.whatsapp.com/send?phone=5511954496048&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20suporte%20jur%C3%ADdico%20empresarial.";

export const INSTAGRAM = "https://www.instagram.com/sociilaw/";
export const FACEBOOK = "https://www.facebook.com/sociilaw";
export const TWITTER = "https://twitter.com/sociilaw";
export const LINKEDIN = "https://www.linkedin.com/company/sociilaw/";
export const YOUTUBE =
  "https://www.youtube.com/channel/UCyyQ2uuYDN5o8IHUd8Phqew";
export const MEDIUM = "https://medium.com/@sociilaw";
export const JUSBRASIL = "https://sociilaw.jusbrasil.com.br/";

export const WHATSAPP_DREAMERS =
  "https://api.whatsapp.com/send?phone=5511954496048&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Dreamers%2C%20voc%C3%AA%20poderia%20me%20ajudar%3F";
export const WHATSAPP_FOUNDERS =
  "https://api.whatsapp.com/send?phone=5511954496048&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Founders%2C%20voc%C3%AA%20poderia%20me%20ajudar%3F";
export const WHATSAPP_INNOVATORS =
  "https://api.whatsapp.com/send?phone=5511954496048&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Innovators%2C%20voc%C3%AA%20poderia%20me%20ajudar%3F";

export const REGISTRY_50_OFF = "/registro50off";
export const WHATSAPP_EDGAR =
  "https://api.whatsapp.com/send?phone=5511954496048&text=Ol%C3%A1!%20Gostaria%20de%20registrar%20minha%20marca.%20Vim%20pelo%20Edgar%20Ueda!";

export const REGISTRO_WHATSAPP_LINK_1 =
  "https://api.whatsapp.com/send?phone=5511954496048&text=Ol%C3%A1%2C%20gostaria%20de%20iniciar%20uma%20an%C3%A1lise%20de%20viabilidade%20de%20marca%20gratuita.%20";
export const REGISTRO_WHATSAPP_LINK_2 =
  "https://api.whatsapp.com/send?phone=5511954496048&text=Ol%C3%A1%2C%20gostaria%20de%20solicitar%20uma%20an%C3%A1lise%20de%20viabilidade%20de%20marca%20gratuita.%20";

export const CTA_REGISTRO_MARCA = `https://api.whatsapp.com/send?phone=5511954496048&text=${encodeURI(
  "Olá, gostaria de registrar minha marca! Preciso de mais informações."
)}`;

export const CTA_ANALISE = `https://api.whatsapp.com/send?phone=5511954496048&text=${encodeURI(
  "Olá, preciso analisar a viabilidade da minha marca! Preciso de mais informações."
)}`;

export const CTA_CONTABILIZEI = `https://api.whatsapp.com/send?phone=5511954496048&text=${encodeURI(
  "Olá, sou cliente contabilizei e gostaria de solicitar uma elaboração de contrato."
)}`;
export const CTA_CONTABILIZEI_ANALYSIS = `https://api.whatsapp.com/send?phone=5511954496048&text=${encodeURI(
  "Olá, sou cliente contabilizei e preciso de auxílio para uma análise de contrato."
)}`;

export const CTA_RM_CONTAB = `https://api.whatsapp.com/send?phone=5511954496048&text=${encodeURI(
  "Olá, sou cliente contabilizei e gostaria de registrar minha marca! Preciso de mais informações."
)}`;

export const CTA_VENTIUR = `https://api.whatsapp.com/send?phone=5511954496048&text=${encodeURI(
  "Olá, sou investido da Ventiur e gostaria de solicitar uma elaboração de contrato."
)}`;

export const CTA_RM_VENTIUR = `https://api.whatsapp.com/send?phone=5511954496048&text=${encodeURI(
  "Olá, sou investido da Ventiur e gostaria de registrar minha marca! Preciso de mais informações."
)}`;
