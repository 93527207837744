import React, { useState, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import * as ROUTES from "./constants/routes";
import { history } from "./app/store/history";

import Appbar from "./app/Components/Appbar";
import CookiesAlert from "./app/Components/Alerts/CookiesAlert";
import FirebaseAnalytics from "./firebase/FirebaseAnalytics";
import { useCookies } from "react-cookie";
import { CircularProgress, useMediaQuery } from "@material-ui/core";
import { getAnalytics, logEvent } from "firebase/analytics";

import EdgarPage from "./app/Pages/Redirects/EdgarPage";
import { hotjar } from "react-hotjar";
import LogRocket from "logrocket";
import MetaChat from "./app/Components/MetaChat";

const HomePage = React.lazy(() => import("./app/Pages/Home.jsx"));
const InstitutionalPage = React.lazy(() =>
  import("./app/Pages/Institutional.jsx")
);
const FeaturesPage = React.lazy(() => import("./app/Pages/Features.jsx"));
const EspecialistsPage = React.lazy(() =>
  import("./app/Pages/Especialists.jsx")
);
const SociiPlusPage = React.lazy(() => import("./app/Pages/SociiPlus.jsx"));
const PlansPage = React.lazy(() => import("./app/Pages/Plans.jsx"));
const LandingPage1 = React.lazy(() => import("./app/Pages/LP/Lp.jsx"));
const LandingPage2 = React.lazy(() => import("./app/Pages/LP/Lp2.jsx"));
const RegistroMarcaPage = React.lazy(() =>
  import("./app/Pages/LP/RegistroMarca.jsx")
);
const RegistroMarcaV2Page = React.lazy(() =>
  import("./app/Pages/LP/RegistroMarcaV2.jsx")
);
const RegistroMarcaV3Page = React.lazy(() =>
  import("./app/Pages/LP/RegistroMarcaV3.jsx")
);
const RegistroMarcaV4Page = React.lazy(() =>
  import("./app/Pages/LP/RegistroMarcaV4.jsx")
);
const RegistroMarcaV5Page = React.lazy(() => import("./app/Pages/LP/RM5"));
const ContratosInvestimentoPage = React.lazy(() =>
  import("./app/Pages/LP/ContratosInvestimento.jsx")
);
const RiwPage = React.lazy(() => import("./app/Pages/LP/RIW"));
const AnalysisPage = React.lazy(() => import("./app/Pages/LP/Analysis"));
const NewLeadPage = React.lazy(() =>
  import("./app/Pages/LP/Management/NewLead")
);
const LeadPage = React.lazy(() => import("./app/Pages/LP/Management/Lead"));

function App() {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [route, setRoute] = useState(window.location.pathname);
  const [cookies, setCookie] = useCookies(["acceptCookies"]);

  const isDev = window.location.hostname === "localhost";

  useEffect(() => {
    if (!isDev) {
      hotjar.initialize(2815934, 6);
      LogRocket.init("uqtvp1/sociilaw");
    }

    history.listen((location) => {
      setRoute(location.pathname);
      if (!isDev) hotjar.stateChange(location.pathname);
      console.log(window.scrollTo({ top: 0 }));
    });
  }, []);

  const splitRoute = route.split("/");
  const routeName = "/" + splitRoute[1];

  const displayMetaChat = routeName !== ROUTES.ANALYSIS || !matchesXS;
  const displayAppbar =
    route !== ROUTES.RIW &&
    routeName !== ROUTES.ANALYSIS &&
    route !== ROUTES.CONTABILIZEI_LP &&
    route !== ROUTES.CONTABILIZEI_LP_2 &&
    route !== ROUTES.RM_CONTABILIZEI_LP &&
    route !== ROUTES.RM_CONTABILIZEI_LP_2 &&
    route !== ROUTES.REGISTRO_MARCA_LP_V5 &&
    route !== ROUTES.VENTIUR_LP &&
    route !== ROUTES.RM_VENTIUR_LP &&
    route !== ROUTES.REGISTRO_MARCA_LP_V5_ANALISE &&
    route !== ROUTES.REGISTRO_MARCA_LP_V5_ANALISE_MIN &&
    routeName !== ROUTES.NEW_LEAD &&
    routeName !== ROUTES.LEAD;

  useEffect(() => {
    if (displayAppbar) {
      const root = document.getElementById("root");
      root.classList.add("root-with-padding");
    }
  }, [route]);

  const handleCloseCookiesAlert = () => {
    const analytics = getAnalytics();
    logEvent(analytics, "click_close_cookies_alert");
    setCookie("acceptCookies", true);
  };

  return (
    <Router history={history}>
      <FirebaseAnalytics />
      {displayMetaChat ? <MetaChat /> : null}
      {displayAppbar ? <Appbar route={route} /> : null}
      <Switch>
        <React.Suspense fallback={<CircularProgress color="primary" />}>
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.INSTITUTIONAL} component={InstitutionalPage} />
          <Route path={ROUTES.FEATURES} component={FeaturesPage} />
          <Route path={ROUTES.ESPECIALISTS} component={EspecialistsPage} />
          <Route path={ROUTES.PLANS} component={PlansPage} />
          <Route path={ROUTES.SOCII_PLUS} component={SociiPlusPage} />
          <Route path={ROUTES.LP1} component={LandingPage1} />
          <Route path={ROUTES.LP2} component={LandingPage2} />

          <Route
            path={ROUTES.REGISTRO_MARCA_LP}
            component={RegistroMarcaPage}
          />
          <Route
            path={ROUTES.REGISTRO_MARCA_LP_V2}
            component={RegistroMarcaV2Page}
          />
          <Route
            path={ROUTES.REGISTRO_MARCA_LP_V3}
            component={RegistroMarcaV3Page}
          />
          <Route
            path={ROUTES.REGISTRO_MARCA_LP_V4}
            component={RegistroMarcaV4Page}
          />
          <Route
            path={ROUTES.REGISTRO_MARCA_LP_V5}
            render={(props) => <RegistroMarcaV5Page {...props} page={"rm3"} />}
          />
          <Route
            path={ROUTES.REGISTRO_MARCA_LP_V5_ANALISE}
            render={(props) => (
              <RegistroMarcaV5Page {...props} page={"rm3Analise"} />
            )}
          />
          <Route
            path={ROUTES.CONTABILIZEI_LP}
            render={(props) => (
              <RegistroMarcaV5Page {...props} page={"contab"} />
            )}
          />
          <Route
            path={ROUTES.CONTABILIZEI_LP_2}
            render={(props) => (
              <RegistroMarcaV5Page {...props} page={"contab2"} />
            )}
          />
          <Route
            path={ROUTES.RM_CONTABILIZEI_LP}
            render={(props) => (
              <RegistroMarcaV5Page {...props} page={"rmContab"} />
            )}
          />
          <Route
            path={ROUTES.RM_CONTABILIZEI_LP_2}
            render={(props) => (
              <RegistroMarcaV5Page {...props} page={"rmContab2"} />
            )}
          />
          <Route
            path={ROUTES.VENTIUR_LP}
            render={(props) => (
              <RegistroMarcaV5Page {...props} page={"ventiur"} />
            )}
          />
          <Route
            path={ROUTES.RM_VENTIUR_LP}
            render={(props) => (
              <RegistroMarcaV5Page {...props} page={"rmVentiur"} />
            )}
          />

          <Route
            path={ROUTES.CONTRATOS_INVESTIMENTO_LP}
            component={ContratosInvestimentoPage}
          />
          <Route exact path={ROUTES.REGISTRY_50_OFF} component={EdgarPage} />
          <Route exact path={ROUTES.RIW} component={RiwPage} />
          <Route exact path={ROUTES.ANALYSIS} component={AnalysisPage} />
          <Route
            exact
            path={ROUTES.ANALYSIS_CALLBACK}
            component={AnalysisPage}
          />
          <Route exact path={ROUTES.NEW_LEAD} component={NewLeadPage} />
          <Route exact path={ROUTES.LEAD} component={LeadPage} />

          <Route
            exact
            path={ROUTES.APP}
            component={() => {
              window.location.href = "https://app.sociilaw.com";
            }}
          />
        </React.Suspense>
      </Switch>
      <CookiesAlert
        open={cookies["acceptCookies"] !== "true"}
        handleClose={handleCloseCookiesAlert}
      />
    </Router>
  );
}

export default App;
